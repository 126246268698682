





























import  firebase from 'firebase/app'
import 'firebase/auth'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Footer from '@/components/Footer.vue'

@Component({
  components: {
    Footer
  }
})
export default class App extends Vue {
  dark: boolean = true

  @Watch('dark')
  updateTheme() {
    (this as any).$vuetify.theme.dark = this.dark
  }

  logout () {
     firebase.auth().signOut()
  }
}
